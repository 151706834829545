import gsap from 'gsap';
import swapDOM from "@hellomonday/swap-dom";
import {EditorialModule} from "../modules/editorial/EditorialModule";
import {ImageModule} from "../modules/editorial/ImageModule";
import {SlideshowModule} from "../modules/editorial/SlideshowModule";
import {VideoModule} from "../modules/editorial/VideoModule";
import {SoundModule} from "../modules/editorial/SoundModule";
import {PanZoomModule} from "../modules/editorial/PanZoomModule";
import {ShareModule} from "../modules/editorial/ShareModule";
import {WindowManager} from "../utils/WindowManager";
import {PANO_ACTIVATE} from "../utils/Contants";
import {PanoramaModule} from "../modules/editorial/PanoramaModule";
import {View} from "../modules/View";
import {colorizer} from "../modules/Colorizer";
import {Soundbyte} from "../components/Soundbyte";

export class EditorialPage extends View  {

	public element: HTMLElement;
	public modules: Array<EditorialModule> = [];
	public headerColor: string;
	public bodyColor: string;
	private panoramaModules: Array<PanoramaModule> = [];
	private soundbyteModules: Array<Soundbyte> = [];
	private editorialModulesEl: NodeList;
	private initialColor: string;
	private _onScroll: any = this.onScroll.bind(this);
	private _onResize: any = this.onResize.bind(this);
	private raf: number;

	constructor(element: HTMLElement) {
		super(element)
		this.editorialModulesEl = document.querySelectorAll('.EditorialModule');
		this.headerColor = this.element.dataset.headercolor;
		this.bodyColor = this.element.dataset.bodycolor;
	}

	public addEvents()
	{
		this.removeEvents();
		this.element.addEventListener('scroll', this._onScroll);
		WindowManager.signalResize.add(this._onResize);
	}

	public removeEvents()
	{
		this.element.removeEventListener('scroll', this._onScroll);
		WindowManager.signalResize.remove(this._onResize);
	}

	public show() {
		super.show();
		this.initialColor = colorizer.currentTheme;
		if (this.headerColor) {
			colorizer.changeColor(this.headerColor);
		}
		this.addEvents();
		this.parseModules();
		this.onResize();
		this.startRenderLoop();
	}

	public animateOut() {
		if (this.headerColor) {
			colorizer.changeColor(this.initialColor);
		}
		return super.animateOut();
	}

	public animateOutComplete() {
		this.destroy();
	}

	public startRenderLoop() {
		if (!this.raf) {
			this.renderLoop();
		}
	}

	public stopRenderLoop() {
		cancelAnimationFrame(this.raf);
		this.raf = null;
	}

	private renderLoop = () => {
		this.raf = requestAnimationFrame(this.renderLoop);
		this.render();
	};

	private render() {
		this.modules.forEach((editorialModule: EditorialModule) => {
			editorialModule.render();
		});
	}

	public onResize() {
		this.modules.forEach((editorialModule: EditorialModule) => {
			editorialModule.onResize();
		});
	}

	public onScroll() {
		this.modules.forEach((editorialModule: EditorialModule) => {
			editorialModule.onScroll(this.element.scrollTop);
		});
	}

	public parseModules() {
		this.editorialModulesEl.forEach((moduleElement: HTMLElement) => {
			const classList = moduleElement.classList;
			let module;
			if (classList.contains('ImageModule')) {
				module = new ImageModule(moduleElement);
			}
			else if (classList.contains('SlideshowModule')) {
				module = new SlideshowModule(moduleElement);
			}
			else if (classList.contains('VideoModule')) {
				module = new VideoModule(moduleElement);
			}
			else if (classList.contains('SoundModule')) {
				module = new SoundModule(moduleElement);
			}
			else if (classList.contains('PanZoomModule')) {
				module = new PanZoomModule(moduleElement);
			}
			else if (classList.contains('PanoramaModule')) {
				module = new PanoramaModule(moduleElement);
				this.panoramaModules.push(module);
				module.on(PANO_ACTIVATE, () => {
					this.panoramaModules.forEach((module) => {
						module.deactivate();
					})
				});
			}
			else if (classList.contains('ShareModule')) {
				module = new ShareModule(moduleElement);
			}
			if (module) {
				module.on('pausePlayers', this.pausePlayers.bind(this));
				this.modules.push(module);
			}

			const soundbyte: HTMLElement = moduleElement.querySelector('.Soundbyte');
			if (soundbyte) {
				this.soundbyteModules.push(
					new Soundbyte(soundbyte, this.pausePlayers.bind(this))
				);
			}
		});
	}

	private pausePlayers({el: el}) {
		for (const mod of this.modules) {
			const classList = mod.element.classList;

			if ((classList.contains('SoundModule') || classList.contains('VideoModule')) && el !== mod.mediaElement ) {
				mod.pause();
			}
		}

		for (const sound of this.soundbyteModules) {
			if (el !== sound.element && sound.element.classList.contains('playing')) {
				sound.pause();
			}
		}
	}

	public destroy() {
		super.destroy();
		if (this.element.parentElement) {
			this.element.parentElement.innerHTML = '';
		}
		this.modules.forEach((editorialModule: EditorialModule) => {
			editorialModule.destroy();
		});
		this.stopRenderLoop();
		this.removeEvents();
	}
}
