import 'lazysizes';
import { WindowManager } from './utils/WindowManager';
import { gallery3d } from './modules/gallery/Gallery3d';
import navaid from 'navaid';
import {
	ABOUT,
	BASE_PATH,
	CONTACT,
	CURRENT_3D_VIEW,
	CURRENT_OVERLAY,
	EVENTS,
	FIRST_ROUTE,
	LAST_3D_PATH,
	LAST_OVERLAY,
	LOAD_COMPLETE,
	LOAD_PROGRESS,
	OVERLAY_LAYER,
	PROJECT_INDEX,
	PROJECT_PAGE,
	PROGRAMMES,
	ROOM,
	UNIT_BROWSER,
	UNIT_PAGE,
	VIDEO_URL_MAP
} from './utils/Contants';
import State from '@hellomonday/state';
import { ProjectPage } from './templates/ProjectPage';
import { UnitPage } from './templates/UnitPage';
import swapDOM from '@hellomonday/swap-dom';
import { showData } from './modules/ShowData';
import { projectIndexPage } from './templates/ProjectIndexPage';
import { AboutPage } from './templates/AboutPage';
import { ProgrammesPage } from './templates/ProgrammesPage';
import { EventsPage } from './templates/EventsPage';
import { mainMenu } from './modules/MainMenu';
import { mainNav } from './modules/MainNav';
import { unitBrowser } from './modules/gallery/UnitBrowser';
import { room3d } from './modules/gallery/Room3d';
import { Globals } from './utils/Globals';
import { colorizer } from './modules/Colorizer';
import { ContactPage } from './templates/ContactPage';
import { clickBlocker, UNBLOCK } from './modules/ClickBlocker';
import { cursorManager } from './modules/CursorManager';
import { introPage } from './templates/IntroPage';
import Pusher from 'pusher-js';
import { multiuserPusher } from './modules/MultiuserPusher';
import { ANIMATE_IN, ANIMATE_OUT } from './modules/View';
import { roomProjectMenu } from './modules/gallery/RoomProjectMenu';
import { hoverTouchUnstick, isTouch, setPageTitle } from './utils/Helpers';

export const router = navaid('/');
export const state = new State();

class Main {
	private pendingRoute: boolean;

	constructor() {
		this.initialize();
	}

	private async initialize() {
		if (isTouch()) {
			document.body.classList.add('isTouch');
			hoverTouchUnstick();
		}

		state.setValue(CURRENT_3D_VIEW, null);
		state.setValue(OVERLAY_LAYER, null);
		state.setValue(VIDEO_URL_MAP, {});

		//Enable pusher logging - don't include this in production
		//Pusher.logToConsole = true;
		multiuserPusher.initialize();

		Globals.MAIN_LOGO = document.body.querySelector('.main-logo');
		WindowManager.getInstance();

		await introPage.animateIn();
		await showData.load();

		gallery3d.initialize();
		colorizer.initialize();
		projectIndexPage.initialize();
		mainMenu.initialize();
		mainNav.initialize();

		this.initGlobalEvents();
		this.initRouter();
		this.favicon();
	}

	private initGlobalEvents() {
		state.on(CURRENT_OVERLAY, this.onOverlaySwitch.bind(this));
		state.on(CURRENT_3D_VIEW, this.on3dViewSwitch.bind(this));

		unitBrowser.on(LOAD_PROGRESS, event => {
			introPage.progress = event.progress * 0.5 + 0.5;
		});

		unitBrowser.on(LOAD_COMPLETE, () => {
			introPage.loadComplete(true);
		});

		room3d.on(LOAD_PROGRESS, event => {
			introPage.progress = event.progress * 0.5 + 0.5;
		});

		room3d.on(LOAD_COMPLETE, () => {
			introPage.loadComplete();
		});

		mainMenu.on(ANIMATE_IN, () => {
			gallery3d.zoomOut();
			mainNav.animateOut();
			unitBrowser.animateOutShowToggle();
			roomProjectMenu.animateOut();
		});

		mainMenu.on(ANIMATE_OUT, () => {
			gallery3d.zoomNormal();
			if (state.getValue(CURRENT_3D_VIEW).id == UNIT_BROWSER) {
				mainNav.animateIn();
				unitBrowser.animateInShowToggle();
			} else if (state.getValue(CURRENT_3D_VIEW).id == ROOM) {
				roomProjectMenu.animateIn();
			}
		});

		clickBlocker.on(UNBLOCK, () => {
			if (this.pendingRoute) {
				this.pendingRoute = false;
				router.run(window.location.pathname);
			}
		});

		addEventListener('click', e => {
			const el = (e.target as HTMLElement).closest('a');
			if (el && location.pathname == el.pathname) e.preventDefault();
			if (el && el.classList.contains('close-overlay-button')) {
				e.preventDefault();
				const previous3dPath = state.getValue(LAST_3D_PATH);
				if (!previous3dPath) {
					router.route(BASE_PATH);
				} else {
					router.route(previous3dPath);
				}
			}
		});
	}

	private initRouter() {
		state.setValue(FIRST_ROUTE, true);

		router
			// Index
			.on('/', params => {
				if (this.onRouteStart()) {
					state.setValue(CURRENT_3D_VIEW, {
						id: UNIT_BROWSER,
						path: '/',
						slug: '/'
					});
					state.setValue(CURRENT_OVERLAY, null);
				}
			})

			// 3d Room
			.on('/room/:slug', params => {
				if (this.onRouteStart()) {
					state.setValue(CURRENT_3D_VIEW, {
						id: ROOM,
						path: BASE_PATH + 'room/' + params.slug,
						slug: params.slug,
						selected: false
					});
					state.setValue(CURRENT_OVERLAY, null);
				}
			})

			// Project Index
			.on('/project-index/:filter?', params => {
				if (this.onRouteStart()) {
					state.setValue(CURRENT_OVERLAY, {
						id: PROJECT_INDEX,
						instance: projectIndexPage,
						title: 'Project Index',
						path: BASE_PATH + 'project-index/' + params.filter,
						filterId: params.filter,
						transition: 'slide'
					});
				}
			})

			// About Page Handler
			.on('/about', params => {
				if (this.onRouteStart()) {
					state.setValue(CURRENT_OVERLAY, {
						id: ABOUT,
						class: AboutPage,
						path: BASE_PATH + 'about',
						transition: 'slide'
					});
				}
			})

			// Progrmmes Page Handler
			.on('/programmes', params => {
				if (this.onRouteStart()) {
					state.setValue(CURRENT_OVERLAY, {
						id: PROGRAMMES,
						class: ProgrammesPage,
						path: BASE_PATH + 'programmes',
						transition: 'slide'
					});
				}
			})

			// Contact Page Handler
			.on('/contact', params => {
				if (this.onRouteStart()) {
					state.setValue(CURRENT_OVERLAY, {
						id: CONTACT,
						class: ContactPage,
						path: BASE_PATH + 'contact',
						transition: 'slide'
					});
				}
			})

			// Events Page Handler
			.on('/events', params => {
				if (this.onRouteStart()) {
					state.setValue(CURRENT_OVERLAY, {
						id: EVENTS,
						class: EventsPage,
						path: BASE_PATH + 'events',
						transition: 'slide'
					});
				}
			})

			// Unit Page Handler
			.on('/:slug', params => {
				if (this.onRouteStart()) {
					state.setValue(CURRENT_OVERLAY, {
						id: UNIT_PAGE,
						class: UnitPage,
						path: BASE_PATH + params.slug,
						unitSlug: params.slug,
						transition: 'slide'
					});
				}
			})

			// Project Page Handler
			.on('/:unit/:slug', params => {
				if (this.onRouteStart()) {
					const overlayParams = {
						id: PROJECT_PAGE,
						class: ProjectPage,
						unitSlug: params.unit,
						projectSlug: params.slug,
						path: BASE_PATH + params.unit + '/' + params.slug,
						transition: 'fade'
					};

					state.setValue(CURRENT_3D_VIEW, {
						id: ROOM,
						path: BASE_PATH + 'room/' + params.unit,
						slug: params.slug,
						selected: true,
						onComplete: () => {
							state.setValue(CURRENT_OVERLAY, overlayParams);
						}
					});
				}
			});

		router.listen();
	}

	private onRouteStart() {
		if (clickBlocker.blocked) {
			this.pendingRoute = true;
			return false;
		} else {
			return true;
		}
	}

	// State handler for Overlay views
	async onOverlaySwitch(stateItem) {
		if (!stateItem.previous && !stateItem.current) {
			return;
		}

		clickBlocker.block();

		// Handle previous state
		if (stateItem.previous) {
			state.setValue(LAST_OVERLAY, stateItem.previous);
			if (stateItem.previous.transition == 'slide') {
				gallery3d.slideDown();
				mainMenu.slideDown();
			}
			if (stateItem.previous.instance) {
				await stateItem.previous.instance.animateOut();
			}
			document.querySelector(OVERLAY_LAYER).innerHTML = '';
		}

		// Handle current state
		if (stateItem.current) {
			cursorManager.showLoading();
			if (state.getValue(FIRST_ROUTE)) {
				if (isTouch()) {
					introPage.loadComplete();
				} else {
					await introPage.animateOut();
				}
			} else {
				if (stateItem.current.instance) {
					setPageTitle(stateItem.current.title + ' | ' + document.body.dataset.pagetitle);
				} else {
					await swapDOM(stateItem.current.path, OVERLAY_LAYER);
					this.trackPageView(stateItem.current.path);
				}
				colorizer.update();
			}
			cursorManager.hideLoading();
			if (!stateItem.current.instance) {
				stateItem.current.instance = new stateItem.current.class(document.querySelector(OVERLAY_LAYER).firstElementChild);
			}
			stateItem.current.instance.animateIn();
			if (stateItem.current.transition == 'slide') {
				mainMenu.slideUp();
				await gallery3d.slideUp();
			}
		}
		state.setValue(FIRST_ROUTE, false);
		clickBlocker.unblock();
	}

	// State handler for 3d views
	async on3dViewSwitch(stateItem) {
		clickBlocker.block();
		gallery3d.show();
		gallery3d.start();

		// Switch to the 3d Room
		if (stateItem.current.id == ROOM) {
			if (stateItem.current.selected) {
				mainMenu.animateOut();
			} else {
				setPageTitle(showData.getUnitBySlug(stateItem.current.slug).code + ' | ' + document.body.dataset.pagetitle);
			}

			if (stateItem.previous && stateItem.previous.id == UNIT_BROWSER) {
				cursorManager.showLoading();
				mainMenu.animateOut();
				mainNav.animateOut();
				await unitBrowser.animateOut();
			}

			await room3d.load(stateItem.current.slug);
			await room3d.animateIn(stateItem.current.selected);
		}
		// Switch to the Unit Browser
		else {
			setPageTitle(document.body.dataset.pagetitle);
			if (stateItem.previous && stateItem.previous.id == ROOM) {
				mainMenu.animateOut();
				await room3d.animateOut();
			}
			await unitBrowser.load();
			await unitBrowser.animateIn();
		}
		state.setValue(LAST_3D_PATH, stateItem.current.path);
		state.setValue(FIRST_ROUTE, false);
		this.trackPageView(stateItem.current.path);
		clickBlocker.unblock();
		if (stateItem.current.onComplete) {
			stateItem.current.onComplete();
		}
	}

	private favicon() {
		const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

		if (darkModeMediaQuery.matches) {
			const favicon = document.getElementById('favicon');
			favicon.setAttribute('href', '/assets/images/favicons/favicon_32x32_white.png');
		}
	}

	private trackPageView(url: string) {
		window.gtag('config', window.gtagId, { page_path: url });
	}
}

window.onload = () => {
	(window as any).Main = new Main();
};
