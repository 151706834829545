import { Color, DoubleSide, Group, Material, Mesh, MeshBasicMaterial, Object3D, Vector3 } from 'three';
import { MediaMaterial } from './MediaMaterial';
import { FloorEntranceTitleCard } from './FloorEntranceTitleCard';
import { unitBrowser } from './UnitBrowser';
import gsap from 'gsap';
import { OFF_BLACK_HEX } from '../../utils/Contants';
import { isTouch } from '../../utils/Helpers';
import { WindowManager } from '../../utils/WindowManager';

export class FloorEntrance3d {
	public container: Object3D;
	public readonly height: number = 3.93;
	public readonly width: number = this.height * 1.77;
	public readonly data: any;
	public titleCard: FloorEntranceTitleCard;
	public mediaMaterial: MediaMaterial;
	public typePaused: boolean = false;
	public videoPaused: boolean = false;
	public positionBrowser: Vector3 = new Vector3();
	public positionIntro: Vector3 = new Vector3();
	public wallMesh: Mesh;
	public introBoxMesh: Mesh;
	public random: number = Math.random();
	private index: number = 0;
	private floorEntrance3dGroup: Group;
	private doorMesh: Mesh;
	private doorwayMesh: Mesh;
	private doorContainerMesh: Mesh;
	private floorMesh: Mesh;
	private shadowSlideMesh: Mesh;
	private shadowInlayMesh: Mesh;
	private shadowSlideInitPosX: number;

	constructor(unitData: any, index: number) {
		this.container = new Object3D();

		this.mediaMaterial = new MediaMaterial();

		this.floorEntrance3dGroup = unitBrowser.doorwayMesh.clone();
		this.floorEntrance3dGroup.position.y = -this.height / 2;
		this.container.add(this.floorEntrance3dGroup);

		this.wallMesh = this.floorEntrance3dGroup.getObjectByName('wall') as Mesh;
		this.doorMesh = this.floorEntrance3dGroup.getObjectByName('door') as Mesh;
		this.doorContainerMesh = this.floorEntrance3dGroup.getObjectByName('door_container') as Mesh;
		this.floorMesh = this.floorEntrance3dGroup.getObjectByName('floor') as Mesh;
		this.doorwayMesh = this.floorEntrance3dGroup.getObjectByName('doorway') as Mesh;
		this.shadowSlideMesh = this.floorEntrance3dGroup.getObjectByName('door_shadow_slide') as Mesh;
		this.shadowInlayMesh = this.floorEntrance3dGroup.getObjectByName('door_shadow_inlay') as Mesh;
		this.introBoxMesh = this.floorEntrance3dGroup.getObjectByName('intro-box') as Mesh;

		this.doorwayMesh.visible = false;
		this.floorMesh.visible = false;

		this.wallMesh.material = this.doorMesh.material = this.mediaMaterial;
		this.floorMesh.material = unitBrowser.floorCeilingMaterial;
		this.shadowSlideInitPosX = this.shadowSlideMesh.position.x;

		this.data = unitData;
		this.index = index;

		this.titleCard = new FloorEntranceTitleCard(this.data);
		unitBrowser.titleCardsContainer.append(this.titleCard.element);
	}

	public async load() {
		const isPhone = isTouch();
		const videoURL = isPhone ? this.data.heroVimeoUrl_640 : this.data.heroVimeoUrl_1280;
		const imageParams = isPhone ? '?w=512&h=256&fit=scale' : '?w=1024&h=512&fit=scale';
		const imageUrl = this.data.heroImageUrl + imageParams;

		if (videoURL) {
			const vidPromise = await this.mediaMaterial.loadVideo(videoURL, false);
			if (!this.mediaMaterial.videoLoaded) {
				console.log('Unit Browser: no video loaded - load the image', imageUrl);
				return this.mediaMaterial.loadImage(imageUrl, false);
			} else {
				return vidPromise;
			}
		} else if (this.data.heroImageUrl) {
			return this.mediaMaterial.loadImage(imageUrl, false);
		}
		return null;
	}

	public resize() {
		this.titleCard.resize();
	}

	public render() {
		if (!this.typePaused) {
			this.titleCard.render();
		}

		if (!this.videoPaused) {
			this.mediaMaterial.render();
		}
	}

	public animateIn() {
		this.animateOut(0);
		gsap.to(this.shadowInlayMesh.material, 0.5, { opacity: 0 });
		gsap.to(this.doorContainerMesh.position, 2, { z: 0, ease: 'Power1.easeInOut' });
		gsap.to(this.shadowSlideMesh.position, 2, { x: this.shadowSlideInitPosX, ease: 'Power1.easeInOut' });
		gsap.to(this.shadowSlideMesh.material, 2, { opacity: 0, ease: 'Power1.easeInOut' });
		gsap.to([this.mediaMaterial.color, (this.doorwayMesh.material as MeshBasicMaterial).color], 1.5, { r: 1, g: 1, b: 1 });
	}

	public animateOut(isAnimated: number = 1) {
		gsap.to(this.shadowInlayMesh.material, 0.5 * isAnimated, { opacity: 1 });
		gsap.to(this.doorContainerMesh.position, 2 * isAnimated, { z: -1, ease: 'Power1.easeInOut' });
		gsap.to(this.shadowSlideMesh.position, 2 * isAnimated, { x: 0, ease: 'Power1.easeInOut' });
		gsap.to(this.shadowSlideMesh.material, 2 * isAnimated, { opacity: 1, ease: 'Power1.easeInOut' });
		gsap.to([this.mediaMaterial.color, (this.doorwayMesh.material as MeshBasicMaterial).color], 1.5 * isAnimated, { r: 0, g: 0, b: 0, delay: 0.5 * isAnimated });
	}

	public resumeType() {
		this.typePaused = false;
	}

	public pauseType() {
		this.typePaused = true;
	}

	public resumeVideo() {
		this.videoPaused = false;
		this.mediaMaterial.playVideo();
	}

	public pauseVideo() {
		this.videoPaused = true;
		this.mediaMaterial.pauseVideo();
	}

	public reset() {
		gsap.set(this.doorContainerMesh.position, { z: 0 });
		gsap.set(this.shadowInlayMesh.material, { opacity: 0 });
		gsap.set(this.shadowSlideMesh.position, { x: this.shadowSlideInitPosX });
		gsap.set(this.shadowSlideMesh.material, { opacity: 0 });
		gsap.set([this.mediaMaterial.color, (this.doorwayMesh.material as MeshBasicMaterial).color], { r: 1, g: 1, b: 1 });
	}

	public fadeInIntroBox(speed: number = 1, delay: number = 0) {
		gsap.to((this.introBoxMesh.material as MeshBasicMaterial).color, speed, { r: 0.1, g: 0.1, b: 0.1, delay: delay });
	}

	public fadeOutIntroBox(speed: number = 1, delay: number = 0) {
		gsap.to((this.introBoxMesh.material as MeshBasicMaterial).color, speed, { r: 0, g: 0, b: 0, delay: delay, overwrite: true });
	}

	public showEntranceMeshes() {
		this.doorwayMesh.visible = true;
		this.floorMesh.visible = true;
	}

	public show() {
		this.titleCard.show();
	}

	public hide() {
		this.titleCard.hide();
		this.pauseType();
		this.pauseVideo();
	}

	public clear() {
		this.mediaMaterial.clear();
	}
}
