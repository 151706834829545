import { FloorEntrance3d } from './FloorEntrance3d';
import { camera, gallery3d, lookAtPosition, renderer, scene } from './Gallery3d';
import {clamp, connectDeviceOrientation, detectTrackPad, isTouch, shortestAngle, shuffleArray} from '../../utils/Helpers';
import gsap from 'gsap';
import { Color, DoubleSide, Euler, Group, Mesh, MeshBasicMaterial, Object3D, PerspectiveCamera, Vector2, Vector3 } from 'three';
import { showData } from '../ShowData';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as THREE from 'three';
import { WindowManager } from '../../utils/WindowManager';
import { cursorManager } from '../CursorManager';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import {AUTUMN, BPRO, COLOR_AUTUMN, COLOR_BPRO, FIRST_ROUTE, LOAD_COMPLETE, LOAD_PROGRESS, OFF_BLACK_DEC, OFF_BLACK_HEX} from '../../utils/Contants';
import Emitter from '@hellomonday/emitter';
import { mainNav } from '../MainNav';
import { mainMenu } from '../MainMenu';
import { state } from '../../Main';
import { clickBlocker } from '../ClickBlocker';
import { audioController, TICK, WHOOSH_DOOR } from '../../components/AudioController';
import { DeviceOrientationControls } from 'three/examples/jsm/controls/DeviceOrientationControls';
import {colorizer, THEME_DARK, THEME_LIGHT} from "../Colorizer";

export const VIEW_INTRO: string = 'view_intro';
export const VIEW_NORMAL: string = 'view_normal';

const INTRO_LAYOUT = [
	[1, 0, 1, 1, 0],
	[0, 1, 1, 1, 1],
	[1, 1, 1, 1, 1],
	[1, 1, 1, 1, 1],
	[1, 1, 1, 1, 0],
	[0, 1, 1, 0, 1]
];

const AUTUMN_POSITION = 16;
const camPositionZ = 3.5;
const INTRO_ROTATION = new Vector3(-.5, 0, 0);

export class UnitBrowser extends Emitter {
	public showing: boolean;
	public container: Object3D = new Object3D();
	public titleCardsContainer: HTMLElement;
	public doorwayMesh: Group;
	public floorCeilingMaterial = new MeshBasicMaterial({ color: OFF_BLACK_DEC, side: DoubleSide });
	public loaded: boolean;
	public pendingAction: string;
	private progressBar;
	private targetMousePosition: Vector2 = new Vector2(0, 0);
	private currentMousePosition: Vector2 = new Vector2(0, 0);
	private scrollDummy: HTMLElement;
	private introTitleScreen: HTMLElement;
	private introTitleContainer: HTMLElement;
	private titleElPro: HTMLElement;
	private titleElAutumn: HTMLElement;
	private showToggleNav: HTMLElement;
	private introTitleScreenMaskPct: number = 1;
	private proTitleScreen: HTMLElement;
	private autumnTitleScreen: HTMLElement;
	private exploreButton: HTMLElement;
	private currentIntroTitle: string;
	private floorEntrances: Array<FloorEntrance3d> = [];
	private targetY3d: number = 0;
	private currentY3d: number = 0;
	private currentUnitIndex: number;
	private snapIndex = 0;
	private onScroll: any = this._onScroll.bind(this);
	private onWheel: any = this._onWheel.bind(this);
	private onMouseMove: any = this._onMouseMove.bind(this);
	private onMouseLeave: any = this._onMouseLeave.bind(this);
	private onOrientationChange: any = this._onOrientationChange.bind(this);
	private defaultBackgroundColor: string = '000000';
	private isMouseWheel: boolean = null;
	private wheelCount: number = 0;
	private delayedCall;
	private currentView: string;
	private animatingIntro: boolean;
	private isAnimatingScroll: boolean;
	private introSplitInComplete = false
	private delayedResumeVideoCall;
	private wallMeshes: Array<Object3D> = [];
	private isTouch: boolean = isTouch();
	private dummyCamera: PerspectiveCamera;
	private currentDummyCamRot: Euler = new Euler();
	private lastDummyCamRot: Euler = new Euler();
	private orientationControls: DeviceOrientationControls;
	private scaleFactor = 0;

	constructor() {
		super();
	}

	public initialize() {
		this.titleCardsContainer = gallery3d.element.querySelector('.title-cards');
		this.scrollDummy = gallery3d.element.querySelector('.scroll-dummy');
		this.introTitleScreen = gallery3d.element.querySelector('.title-screen');
		this.introTitleContainer = gallery3d.element.querySelector('.title-screen .titles');
		this.proTitleScreen = gallery3d.element.querySelector('.title-screen .b-pro');
		this.autumnTitleScreen = gallery3d.element.querySelector('.title-screen .autumn');
		this.showToggleNav = gallery3d.element.querySelector('.show-toggle-nav');
		this.exploreButton = gallery3d.element.querySelector('.title-screen .explore');
		this.progressBar = gallery3d.element.querySelector('.progress-bar');
		this.titleElPro = this.introTitleContainer.querySelector('.b-pro .title-text');
		this.titleElAutumn = this.introTitleContainer.querySelector('.autumn .title-text');
		gsap.registerPlugin(ScrollToPlugin);
		this.scrollDummy.scrollTop = 0;

		if (isTouch()) {
			this.proTitleScreen.addEventListener('click', (e) => {
				if (this.currentIntroTitle == BPRO) {
					this.onProTitleClick(e);
				} else {
					this.onProOver();
				}
			});
			this.autumnTitleScreen.addEventListener('click', (e) => {
				if (this.currentIntroTitle == AUTUMN) {
					this.onAutumnTitleClick(e);
				} else {
					this.onAutumnOver();
				}
			});
		} else {
			this.proTitleScreen.addEventListener('mouseover', this.onProOver);
			this.autumnTitleScreen.addEventListener('mouseover', this.onAutumnOver);
			this.proTitleScreen.addEventListener('click', this.onProTitleClick);
			this.autumnTitleScreen.addEventListener('click', this.onAutumnTitleClick);
		}

		this.showToggleNav.children[0].addEventListener('click', () => {
			this.animateScrollTo(0, 1);
		});

		this.showToggleNav.children[1].addEventListener('click', () => {
			this.animateScrollTo(AUTUMN_POSITION, 1);
		});
	}

	public async load() {
		if (this.loaded) {
			return;
		} else {
			cursorManager.showLoading();
			if (!this.doorwayMesh) {
				this.doorwayMesh = (await this.loadDoorway()) as Group;
				this.setupFloors();
			}

			let loadIndex = 0;
			const promises = this.floorEntrances.map((floorEntrance, index) =>
				floorEntrance.load().then(something => {
					loadIndex++;
					this.emit(LOAD_PROGRESS, { progress: loadIndex / this.floorEntrances.length });
				})
			);
			return Promise.all(promises).then(values => {
				this.emit(LOAD_COMPLETE, { progress: loadIndex / this.floorEntrances.length });
				cursorManager.hideLoading();
				this.loaded = true;
			});
		}
	}

	private setupFloors() {
		const programmes = showData.json.show.programmes;
		let index = 0;
		programmes.forEach(programme => {
			programme.units.forEach((unitData: any) => {
				const floorEntrance3d = new FloorEntrance3d(unitData, index);
				floorEntrance3d.positionBrowser.set(0, index * -floorEntrance3d.height, 0);
				this.container.add(floorEntrance3d.container);
				this.floorEntrances.push(floorEntrance3d);
				//@ts-ignore
				floorEntrance3d.wallMesh.initPos = floorEntrance3d.wallMesh.position;
				this.wallMeshes.push(floorEntrance3d.wallMesh);
				index++;
			});
		});

		//set positions for intro grid
		let itemIndex = 0;
		const randomFloorEntrances = [...this.floorEntrances];
		shuffleArray(randomFloorEntrances);
		for (let row = 0; row < INTRO_LAYOUT.length; row++) {
			for (let col = 0; col < INTRO_LAYOUT[row].length; col++) {
				if (INTRO_LAYOUT[row][col] == 1) {
					const item = randomFloorEntrances[itemIndex];
					if (item) {
						const x = col * (item.width + 1) - item.width * 2 - (row % 2 == 0 ? item.width / 2 : 0);
						const y = row * -(item.height + 1) + item.height * 2;
						const z = -10;
						item.positionIntro.set(x, y, z);
					}
					itemIndex++;
				}
			}
		}
	}

	private loadDoorway() {
		return new Promise(resolve => {
			const gltf_loader = new GLTFLoader();
			const tex_loader = new THREE.TextureLoader();
			const modelPath = '/assets/models';
			gltf_loader.load(modelPath + '/enter_unit_door.glb', (obj: any) => {
				obj.scene.traverse(child => {
					if (child instanceof THREE.Mesh) {
						let tex = child.material.map;
						//load PNG transparent seperate, wasn't exporting with GLTF from cinema
						if (child.name == 'door_shadow_slide') {
							tex = tex_loader.load(modelPath + '/door_shadow_slide.png');
							child.material = new MeshBasicMaterial({ map: tex, transparent: true, opacity: 0 });
						} else if (child.name == 'door_shadow_inlay') {
							tex = tex_loader.load(modelPath + '/door_shadow_inlay.png');
							child.material = new MeshBasicMaterial({ map: tex, transparent: true, opacity: 0 });
						} else if (child.name == 'intro-box') {
							child.material = new MeshBasicMaterial({ color: 0x000000 });
						} else {
							child.material = new MeshBasicMaterial({ map: tex });
						}
					}
				});
				resolve(obj.scene);
			});
		});
	}

	private show() {
		this.showing = true;
		//fix for snap scroll chrome bug
		document.body.style.overflow = 'auto';
		this.render();
		scene.add(this.container);
	}

	public animateIn() {
		this.show();
		this.setView(state.getValue(FIRST_ROUTE) ? VIEW_INTRO : VIEW_NORMAL);
		gsap.to(this.progressBar, 0.3, { y: 0 });

		if (this.currentView == VIEW_NORMAL) {
			return new Promise(resolve => {
				gsap.to(gallery3d.webglContainer, 1, { opacity: 1 });
				mainNav.animateIn();
				mainMenu.animateInToggle();
				colorizer.changeColor(THEME_LIGHT);
				this.animateInShowToggle();
				this.onScroll();
				this.setCurrentUnitIndex(this.currentUnitIndex, true);
				if (this.pendingAction) {
					const index = showData.getUnitBySlug(this.pendingAction).index;
					this.scrollToUnit(index);
					audioController.play(WHOOSH_DOOR);
					this.floorEntrances[index].animateIn();
					camera.position.z = 0;
					gsap.to(camera.position, 2, {
						z: camPositionZ,
						ease: 'Power1.easeInOut',
						onComplete: () => {
							resolve();
						}
					});
					gsap.to(this.titleCardsContainer, 1, { opacity: 1, delay: 1.3 });
					this.pendingAction = null;
				} else {
					if (!mainMenu.animatedIn) {
						gsap.to(this.titleCardsContainer, 1, { opacity: 1 });
					}
					resolve();
				}
			});
		} else if (this.currentView == VIEW_INTRO) {
			return new Promise(resolve => {
				gsap.set(this.introTitleScreen, { autoAlpha: 1 });
				gsap.to(this, 1, {ease: 'Power1.easeInOut',
					introTitleScreenMaskPct: 0,
					delay: .4,
					onUpdate: () => {
						const h = this.introTitleScreenMaskPct * WindowManager.halfHeight;
						this.introTitleScreen.style.clipPath = 'inset('+ h +'px 0px '+ h +'px 0px)';
					},
					onComplete : () => {
						this.introSplitInComplete = true;
						this.introTitleScreen.style.clipPath = '';
					}
				});

				this.floorEntrances.map((floorEntrance, index) => {
					if (Math.random() < 0.2) {
						floorEntrance.mediaMaterial.playVideo();
					}
					gsap.set(floorEntrance.mediaMaterial.color, { r: 1, g: 1, b: 1});
				});
				gsap.set(camera.position, { x: 0, y: -5, z: 45 });

				resolve();
			});
		}
	}

	public animateOut() {
		if (!this.showing) {
			return;
		}

		return new Promise(resolve => {
			if (this.orientationControls) {
				this.orientationControls.enabled = false;
				gsap.to(camera.rotation, 0.5, { x: 0, y: 0, z: 0 });
			}
			this.animateOutShowToggle();
			gsap.to(this.titleCardsContainer, 0.3, { opacity: 0, overwrite: 'auto' });
			gsap.to(this.progressBar, 0.3, { y: 5 });
			audioController.play(WHOOSH_DOOR);
			this.floorEntrances[this.currentUnitIndex].animateOut();
			gallery3d.setBackground('#' + this.defaultBackgroundColor);
			gsap.to(camera.position, 2, {
				z: 0,
				ease: 'Power1.easeInOut',
				onComplete: () => {
					this.hide();
					resolve();
				}
			});
		});
	}

	public setView(view) {
		this.currentView = view;
		if (this.currentView == VIEW_NORMAL) {
			this.setupViewStateBrowser();
		} else if (this.currentView == VIEW_INTRO) {
			this.setupViewStateIntro();
		}
	}

	private setupViewStateIntro() {
		this.addMouseMoveEvent();
		camera.fov = 40;
		camera.updateProjectionMatrix();
		//gsap.to(gallery3d.webglContainer, 1, { opacity: 1 });
		camera.position.set(0, 0, 5);
		this.container.rotation.set(INTRO_ROTATION.x, INTRO_ROTATION.y, INTRO_ROTATION.z);
		this.floorEntrances.map(floorEntrance => {
			floorEntrance.reset();
			floorEntrance.container.position.copy(floorEntrance.positionIntro);
			floorEntrance.mediaMaterial.color.setRGB(0, 0, 0);
		});
		this.resize();
	}

	private setupViewStateBrowser() {
		this.addEvents();
		this.titleCardsContainer.style.display = 'block';
		this.scrollDummy.style.display = 'block';

		camera.fov = 90;
		camera.updateProjectionMatrix();
		//this.setCurrentUnitIndex(0);
		camera.position.set(0, 0, camPositionZ);
		this.container.rotation.set(0, 0, 0);

		this.floorEntrances.map(floorEntrance => {
			floorEntrance.reset();
			gsap.set(floorEntrance.container.position, { x: 0, y: floorEntrance.positionBrowser.y, z: 0 });
			floorEntrance.showEntranceMeshes();
			floorEntrance.show();
		});
		this.resize();
	}

	private onProOver = () => {
		if (this.currentIntroTitle == BPRO || !this.introSplitInComplete) {
			return;
		}

		const backgroundElPro = this.introTitleContainer.querySelector('.b-pro .background');
		const subTextElPro = this.introTitleContainer.querySelector('.b-pro .sub-text');
		const subTextElAutumn = this.introTitleContainer.querySelector('.autumn .sub-text');
		const backgroundElAutumn = this.introTitleContainer.querySelector('.autumn .background');

		this.currentIntroTitle = BPRO;
		gsap.to(this.introTitleContainer, .5, { y: WindowManager.halfHeight * .5, ease: 'Power1.easeOut' });
		gsap.to(gallery3d.webglContainer, .2, {opacity: 1});
		gsap.to(this.titleElPro, .5, {y: -(WindowManager.height * .75) / 2, scale: 1 + this.scaleFactor});
		gsap.to(subTextElPro, .5, {marginTop: 0, opacity: 1});
		gsap.to(backgroundElPro, .5, {opacity: .6});
		gsap.to(this.titleElAutumn, .5, {y: WindowManager.height * .125, scale: 1 - this.scaleFactor});
		gsap.to(subTextElAutumn, .5, {marginTop: -20, opacity: 0});
		gsap.to(backgroundElAutumn, .5, {opacity: 1});
		gsap.to(camera.position, 1.3, { y: -8 });
		gallery3d.setBackground(COLOR_BPRO);

		this.floorEntrances.map((floorEntrance, index) => {
			gsap.to((floorEntrance.introBoxMesh.material as MeshBasicMaterial).color, 1.3, { r: .224, g: .325, b: .82 });
			if (floorEntrance.data.show == BPRO) {
				gsap.to(floorEntrance.container.position, 1.3, {z: 1 + 3 * floorEntrance.random});
				gsap.to(floorEntrance.mediaMaterial.color, 1.3, { r: 1, g: 1, b: 1 });
			} else {
				gsap.to(floorEntrance.container.position, 1.3, {z: -1 - 3 * floorEntrance.random});
				gsap.to(floorEntrance.mediaMaterial.color, 1.3, { r: .09, g: .17, b: .85 });
			}
		});
	}

	private onAutumnOver = () => {
		if (this.currentIntroTitle == AUTUMN || !this.introSplitInComplete) {
			return;
		}

		const subTextElPro = this.introTitleContainer.querySelector('.b-pro .sub-text');
		const backgroundElPro = this.introTitleContainer.querySelector('.b-pro .background');
		const subTextElAutumn = this.introTitleContainer.querySelector('.autumn .sub-text');
		const backgroundElAutumn = this.introTitleContainer.querySelector('.autumn .background');

		this.currentIntroTitle = AUTUMN;
		gsap.to(this.introTitleContainer, .5, { y: -WindowManager.halfHeight * .5, ease: 'Power1.easeOut' });
		gsap.to(gallery3d.webglContainer, .2, {opacity: 1});
		gsap.to(this.titleElPro, .5, {y: -WindowManager.height * .125, scale: 1 - this.scaleFactor});
		gsap.to(subTextElPro, .5, {marginTop: -20, opacity: 0});
		gsap.to(backgroundElPro, .5, {opacity: 1});
		gsap.to(this.titleElAutumn, .5, {y: (WindowManager.height * .75) / 2, scale: 1 + this.scaleFactor});
		gsap.to(subTextElAutumn, .5, {marginTop: 0, opacity: 1});
		gsap.to(backgroundElAutumn, .5, {opacity: .6});
		gsap.to(camera.position, 1.3, { y: 0 });

		gallery3d.setBackground(COLOR_AUTUMN);

		this.floorEntrances.map((floorEntrance, index) => {
			gsap.to((floorEntrance.introBoxMesh.material as MeshBasicMaterial).color, 1.3, { r: .729, g: .431, b: .322 });
			if (floorEntrance.data.show == BPRO) {
				gsap.to(floorEntrance.container.position, 1.3, {z: -1 - 3 * floorEntrance.random});
				gsap.to(floorEntrance.mediaMaterial.color, 1.3, { r: .78, g: .3, b: .13 });
			} else {
				gsap.to(floorEntrance.container.position, 1.3, {z: 1 + 3 * floorEntrance.random});
				gsap.to(floorEntrance.mediaMaterial.color, 1.3, { r: 1, g: 1, b: 1 });
			}
		});
	}

	private onProTitleClick = (e) => {
		gsap.to(camera.position, 1.3, { y: -2 });
		gsap.to(this.titleElPro, .5, {y: -WindowManager.halfHeight});
		gsap.to(this.introTitleContainer, .5, { y: WindowManager.halfHeight, ease: 'Power1.easeOut' });
		this.animateIntroToBrowser(Math.floor(Math.random() * AUTUMN_POSITION));
	};

	private onAutumnTitleClick = (e) => {
		gsap.to(camera.position, 1.3, { y: -2 });
		gsap.to(this.titleElAutumn, .5, {y: WindowManager.halfHeight});
		gsap.to(this.introTitleContainer, .5, { y: -WindowManager.halfHeight, ease: 'Power1.easeOut' });
		this.animateIntroToBrowser(AUTUMN_POSITION + Math.floor(Math.random() * 3));
	};

	private async animateIntroToBrowser(scrollIndex: number) {
		this.autumnTitleScreen.removeEventListener('mouseover', this.onAutumnOver);
		this.proTitleScreen.removeEventListener('mouseover', this.onProOver);
		this.autumnTitleScreen.removeEventListener('click', this.onAutumnTitleClick);
		this.proTitleScreen.removeEventListener('click', this.onProTitleClick);
		clickBlocker.block();
		this.animatingIntro = true;
		gsap.killTweensOf(this.introTitleScreen);
		await this.animateOutIntro();
		this.setView(VIEW_NORMAL);

		if (scrollIndex < Math.floor(this.floorEntrances.length / 2)) {
			this.scrollDummy.scrollTop = 20000;
		} else {
			this.scrollDummy.scrollTop = 0;
		}

		this.targetMousePosition.y = 4;
		gsap.to(this.targetMousePosition, 2, { y: 0 });
		this.animateScrollTo(scrollIndex, 2, () => {
			clickBlocker.unblock();
			this.animatingIntro = false;
			mainNav.animateIn();
			mainMenu.animateInToggle();
			this.animateInShowToggle();
		});
		gsap.killTweensOf(camera.position);
		gsap.set(camera.position, { z: 5.5 });
		gsap.to(camera.position, 2, { z: camPositionZ, ease: 'Power2.easeOut' });
		gsap.to(this.titleCardsContainer, 1, { opacity: 1, delay: 2 });
		gsap.to(gallery3d.webglContainer, 1, { opacity: 1 });
		this.floorEntrances[0].resumeType();
		this.floorEntrances[0].resumeVideo();
	}

	private animateOutIntro() {
		gallery3d.setBackground('#' + this.defaultBackgroundColor, 1, .5);
		gsap.to([this.titleElPro, this.titleElAutumn], .4, { autoAlpha: 0, delay: .2 });
		gsap.to(this.introTitleScreen, .4, { autoAlpha: 0, delay: .7 });
		this.floorEntrances.map((floorEntrance, index) => {
			const delay = Math.random() * 0.2;
			floorEntrance.fadeOutIntroBox(1, delay);
			gsap.to(floorEntrance.container.position, 1.25, { z: '-=' + (Math.random() * 4 + 8), delay: delay, ease: 'Power1.easeInOut', overwrite: true });
			gsap.to(floorEntrance.mediaMaterial.color, 1.25, { r: 0, g: 0, b: 0, delay: delay, ease: 'Power1.easeInOut', overwrite: true });
		});
		return new Promise(resolve => {
			gsap.to(gallery3d.webglContainer, 1, {
				opacity: 0,
				delay: .5,
				onComplete: () => {
					resolve();
				}
			});
		});
	}

	private hide() {
		this.removeEvents();
		this.showing = false;
		gsap.set(gallery3d.webglContainer, { opacity: 0 });
		document.body.style.overflow = 'hidden';
		this.titleCardsContainer.style.display = 'hide';
		this.scrollDummy.style.display = 'none';
		scene.remove(this.container);
		if (this.isTouch) {
			this.loaded = false;
		}
		this.floorEntrances.map(floorEntrance => {
			floorEntrance.hide();
			if (this.isTouch) {
				floorEntrance.clear();
			}
		});
	}

	private _onScroll(e) {
		const totalHeight = this.scrollDummy.children[0].clientHeight;
		const childHeight = this.scrollDummy.children[0].children[0].clientHeight;
		const pct = this.scrollDummy.scrollTop / (totalHeight - childHeight);
		const totalItems = this.floorEntrances.length - 1;
		const full3dHeight = totalItems * this.floorEntrances[0].height;
		this.targetY3d = -full3dHeight * pct;
		if (this.isTouch) {
			this.targetMousePosition.set(0, 0);
		}
		this.setCurrentUnitIndex(Math.round(totalItems * pct));
		gsap.to(this.progressBar, 0.1, { width: pct * WindowManager.width });
	}

	private addEvents() {
		this.removeEvents();
		this.addMouseMoveEvent();
		gallery3d.element.addEventListener('mouseleave', this.onMouseLeave);
		this.scrollDummy.addEventListener('scroll', this.onScroll);
		this.scrollDummy.addEventListener('wheel', this.onWheel);
		this.addOrientationEvents();
	}

	private removeEvents() {
		this.removeMouseMoveEvent();
		gallery3d.element.removeEventListener('mouseleave', this.onMouseLeave);
		this.scrollDummy.removeEventListener('scroll', this.onScroll);
		this.removeOrientationEvents();
	}

	private addMouseMoveEvent() {
		this.removeMouseMoveEvent();
		gallery3d.element.addEventListener('mousemove', this.onMouseMove);
	}

	private removeMouseMoveEvent() {
		gallery3d.element.removeEventListener('mousemove', this.onMouseMove);
	}

	public enableDeviceOrientation() {
		if (!this.orientationControls) {
			this.dummyCamera = new PerspectiveCamera();
			this.orientationControls = new DeviceOrientationControls(this.dummyCamera);
			this.orientationControls.enabled = false;
		}
	}

	private addOrientationEvents() {
		if (this.orientationControls) {
			this.orientationControls.enabled = true;
			this.orientationControls.update();
			this.lastDummyCamRot.copy(this.dummyCamera.rotation);
			this.currentDummyCamRot.copy(this.dummyCamera.rotation);
			camera.rotation.set(0, 0, 0);
			window.addEventListener('orientationchange', this.onOrientationChange);
		}
	}

	private removeOrientationEvents() {
		this.targetMousePosition.set(0, 0);
		if (this.orientationControls) {
			this.orientationControls.enabled = false;
			window.removeEventListener('orientationchange', this.onOrientationChange);
		}
	}

	private _onMouseMove(event) {
		this.targetMousePosition.x = (event.clientX / WindowManager.width) * 2 - 1;
		this.targetMousePosition.y = -(event.clientY / WindowManager.height) * 2 + 1;
	}

	private _onMouseLeave(event) {}

	private _onWheel(event) {
		if (this.isMouseWheel == null && !this.isTouch) {
			this.isMouseWheel = !detectTrackPad(event);
		}

		if (this.isMouseWheel) {
			event.preventDefault();
			this.wheelCount++;
			this.scrollDummy.classList.add('disableSnap');
			if (this.wheelCount > 4) {
				this.wheelCount = 0;
				this.snapIndex += Math.sign(event.deltaY);
				this.snapIndex = clamp(0, this.floorEntrances.length, this.snapIndex);
				gsap.to(this.scrollDummy, 0.6, { scrollTo: this.snapIndex * WindowManager.height });
			}
		}
	}

	private _onOrientationChange(event) {
		if (isTouch()) {
			this.scrollToUnit(0);
		}
	}

	public resize() {
		if (!this.showing) {
			return;
		}
		if (!isTouch()) {
			this.scrollToUnit(this.currentUnitIndex);
		}

		if (this.currentIntroTitle == BPRO) {
			gsap.set(this.introTitleContainer, { y: WindowManager.halfHeight * .5 });
			gsap.set(this.titleElPro, {y: -(WindowManager.height * .75) / 2});
			gsap.set(this.titleElAutumn, {y: WindowManager.height * .125});
		} else if (this.currentIntroTitle == AUTUMN) {
			gsap.set(this.introTitleContainer,  { y: -WindowManager.halfHeight * .5 });
			gsap.set(this.titleElPro, {y: -WindowManager.height * .125});
			gsap.set(this.titleElAutumn,  {y: (WindowManager.height * .75) / 2});
		}

		this.scaleFactor = WindowManager.width < 800 ? 0 : .2;
		this.floorEntrances.forEach((floorEntrance: FloorEntrance3d) => {
			floorEntrance.resize();
		});
	}

	public render() {
		if (!this.showing) {
			return;
		}

		this.floorEntrances.forEach((floorEntrance: FloorEntrance3d, index: number) => {
			floorEntrance.render();
		});

		if (this.currentView == VIEW_NORMAL) {
			if (this.isTouch) {
				this.currentY3d += (this.targetY3d - this.currentY3d) / 3;
			} else {
				this.currentY3d = this.targetY3d;
			}

			if (this.orientationControls) {
				if (this.orientationControls.enabled) {
					this.currentDummyCamRot.y += (this.dummyCamera.rotation.y - this.currentDummyCamRot.y) / 20;
					this.currentDummyCamRot.x += (this.dummyCamera.rotation.x - this.currentDummyCamRot.x) / 20;
					camera.rotation.y += this.currentDummyCamRot.y - this.lastDummyCamRot.y;
					camera.rotation.y = clamp(-0.5, 0.5, camera.rotation.y);
					camera.rotation.x += this.currentDummyCamRot.x - this.lastDummyCamRot.x;
					camera.rotation.x = clamp(-0.1, 0.1, camera.rotation.x);
					this.lastDummyCamRot.copy(this.currentDummyCamRot);
				}
				this.orientationControls.update();
			} else {
				this.currentMousePosition.y += (this.targetMousePosition.y - this.currentMousePosition.y) / 10;
				this.currentMousePosition.x -= (this.targetMousePosition.x + this.currentMousePosition.x) / 10;
				camera.rotation.set(this.currentMousePosition.y / 10, this.currentMousePosition.x / 10, 0);
			}

			gsap.set(camera.position, { y: this.currentY3d });
		} else if (this.currentView == VIEW_INTRO) {
			this.currentMousePosition.y -= (this.targetMousePosition.y + this.currentMousePosition.y) / 10;
			this.currentMousePosition.x -= (this.targetMousePosition.x + this.currentMousePosition.x) / 10;
			this.container.rotation.set(INTRO_ROTATION.x + this.currentMousePosition.y / 20, INTRO_ROTATION.y, INTRO_ROTATION.z + this.currentMousePosition.x / 20);
		}
	}

	private animateScrollTo(index: number, speed: number = 2, onComplete = null) {
		this.scrollDummy.classList.add('disableSnap');
		clickBlocker.block();
		this.updateShowToggleState(index);
		this.isAnimatingScroll = true;
		this.snapIndex = index;
		gsap.to(this.scrollDummy, speed, {
			scrollTo: this.getScrollTopByIndex(index),
			ease: 'Power2.easeOut',
			onComplete: () => {
				clickBlocker.unblock();
				this.isAnimatingScroll = false;
				this.scrollDummy.classList.remove('disableSnap');
				if (onComplete) {
					onComplete();
				}
			}
		});
	}

	private scrollToUnit(index: number = 0) {
		this.scrollDummy.scrollTop = this.getScrollTopByIndex(index);
	}

	private getScrollTopByIndex(index: number = 0) {
		return index * WindowManager.height;
	}

	private setCurrentUnitIndex(newIndex, force: boolean = false) {
		if (newIndex != this.currentUnitIndex || force) {
			if (this.currentUnitIndex >= 0) {
				if (this.delayedCall) {
					this.delayedCall.kill();
					this.delayedCall = null;
				}
				const floor = this.floorEntrances[this.currentUnitIndex];
				floor.pauseType();
				if (!(this.animatingIntro && this.currentUnitIndex == 0)) {
					floor.pauseVideo();
				}
				if (this.delayedResumeVideoCall) {
					this.delayedResumeVideoCall.kill();
				}
			}

			this.currentUnitIndex = newIndex;
			this.updateShowToggleState();
			if (!force) {
				audioController.play(TICK);
			}

			const curFloor = this.floorEntrances[this.currentUnitIndex];
			curFloor.resumeType();

			this.delayedResumeVideoCall = gsap.delayedCall(1, () => {
				curFloor.resumeVideo();
			});

			const bgColor = curFloor.data.menuBackgroundColor ? curFloor.data.menuBackgroundColor : this.defaultBackgroundColor;
			gallery3d.setBackground('#' + bgColor);
		}
	}

	private updateShowToggleState(index: number = null) {
		const checkIndex = index != null ? index : this.currentUnitIndex;
		if (!this.isAnimatingScroll) {
			if (checkIndex < AUTUMN_POSITION) {
				this.showToggleNav.children[0].classList.add('active');
				this.showToggleNav.children[1].classList.remove('active');
			} else {
				this.showToggleNav.children[0].classList.remove('active');
				this.showToggleNav.children[1].classList.add('active');
			}
		}
	}

	public animateInShowToggle() {
		gsap.to(this.showToggleNav, .5, {autoAlpha: 1});
	}

	public animateOutShowToggle() {
		gsap.to(this.showToggleNav, .5, {autoAlpha: 0});
	}

	public pauseActiveVideo() {
		if (!this.showing) {
			return;
		}
		const floor = this.floorEntrances[this.currentUnitIndex];
		if (floor) {
			floor.pauseVideo();
		}
	}

	public resumeActiveVideo() {
		if (!this.showing) {
			return;
		}
		const floor = this.floorEntrances[this.currentUnitIndex];
		if (floor) {
			floor.resumeVideo();
		}
	}

	public zoomOut(speed) {
		gsap.to(this.scrollDummy, speed, { scale: 0.85, ease: 'Power2.easeOut' });
		gsap.to(this.titleCardsContainer, speed / 2, { opacity: 0, ease: 'Power2.easeOut' });
	}

	public zoomNormal(speed) {
		if (!mainMenu.animatedIn) {
			gsap.to(this.scrollDummy, speed, { scale: 1, ease: 'Power2.easeOut' });
			gsap.to(this.titleCardsContainer, speed / 2, { opacity: 1, ease: 'Power2.easeOut' });
		}
	}
}

export const unitBrowser = new UnitBrowser();
