import { ConeGeometry, Mesh, MeshBasicMaterial, MeshPhysicalMaterial, Object3D, Vector3 } from 'three';
import gsap from 'gsap';

export class Viewer {
	public projectIndex;
	public mesh;
	public offset: Vector3 = new Vector3(Math.random() - 0.5, Math.random() - 0.5, Math.random() - 0.5);

	constructor(coneMesh: Mesh) {
		this.mesh = new Object3D();
		coneMesh.material = new MeshBasicMaterial({ map: (coneMesh.material as MeshPhysicalMaterial).map });
		coneMesh.rotation.y -= Math.PI / 2;
		coneMesh.visible = true;
		this.mesh.add(coneMesh);
	}

	public destroy() {}
}
